const RE_BETA_URL = /beta\.yr\.no/;
const RE_FEATURE_URL = /frontend-feature-/;

const baseUrl = process.env.APP_BASE_URL || 'https://www.yr.no';
const contentBaseUrl = 'https://www.yr.no';
const isBeta = RE_BETA_URL.test(baseUrl);
const isFeature = RE_FEATURE_URL.test(baseUrl);
const isProd = process.env.NODE_ENV === 'production';
const isDev = process.env.NODE_ENV === 'development';
const isBrowserTest = process.env.DEPLOYMENT_ENVIRONMENT === 'browser-test';
const isRelease = isProd && !isBeta && !isFeature;

const settings = {
  api: {
    base: {
      client: '/api/v0',
      // Set at runtime
      server: process.env.API_URL_SERVER || 'https://yr-api-gateway-prod.we.yr.azure.nrk.cloud/api/v0'
    },
    maxAge: 120,
    paths: {
      airQuality: 'locations/{locationId}/airqualityforecast?language={localeCode}',
      article: 'articles/_?language={localeCode}',
      auroraForecast: 'locations/{locationId}/auroraforecast?language={localeCode}',
      autoText: 'locations/{locationId}/forecast/autotext?language={localeCode}',
      birkebeiner: 'routeforecast/birkebeineren/{routeId}?language={localeCode}',
      celestialEventsMultipleDays: 'locations/{locationId}/celestialeventsmultipledays',
      celestialEventsSingleDay: 'locations/{locationId}/celestialeventssingleday?language={localeCode}',
      coastForecast: 'locations/{locationId}/forecast/coast?language={localeCode}',
      currenthour: 'locations/{locationId}/forecast/currenthour',
      extremeEvent: 'warnings/extreme/{eventKey}?language={localeCode}',
      extremeEventList: 'locations/{locationId}/warnings/extreme?language={localeCode}',
      forecast: 'locations/{locationId}/forecast',
      lightning: 'lightning-events?fromHours=24',
      location: 'locations/{locationId}?language={localeCode}',
      locationWarnings: 'locations/{locationId}/warnings?language={localeCode}',
      locationCameras: 'locations/{locationId}/cameras?language={localeCode}',
      log: 'log',
      mapFeature: 'locations/{locationId}/mapfeature?language={localeCode}',
      now: 'locations/{locationId}/forecast/now',
      observations: 'locations/{locationId}/observations',
      pollen: 'locations/{locationId}/pollen?language={localeCode}',
      radar: 'directory/wms/radar?language={localeCode}',
      regions: 'regions/NO?language={localeCode}',
      routeForecastAllMountainpasses: 'routeforecast/mountainpass?language={localeCode}',
      routeForecastMountainpass: 'routeforecast/mountainpass/{routeId}?language={localeCode}',
      searchByPosition: 'locations/search?language={localeCode}&lat={lat}&lon={lon}&accuracy=1000',
      searchByQuery: 'locations/search?language={localeCode}&q={query}',
      serviceAnnouncement: 'serviceannouncements/web?language={localeCode}',
      snowDepth: 'observations/snowdepths/regions/{regionId}?language={localeCode}',
      statisticsLast13Months: 'locations/{locationId}/observations/year',
      statisticsLast30Days: 'locations/{locationId}/observations/month',
      statisticsLast24Hours: 'locations/{locationId}/observations/day',
      statisticsYear: 'locations/{locationId}/observations/year/{year}',
      statisticsDay: 'locations/{locationId}/observations/{date}',
      suggest: 'locations/suggest?language={localeCode}&q={query}',
      subseasonalforecast: 'locations/{locationId}/subseasonalforecast',
      tide: 'locations/{locationId}/tide?language={localeCode}',
      twitter: 'twitter?language={localeCode}',
      warnings: 'warnings?language={localeCode}',
      waterLevel: 'locations/{locationId}/waterlevel?language={localeCode}',
      waterTemperatureLocation: 'locations/{locationId}/nearestwatertemperatures?language={localeCode}',
      waterTemperatureRegion: 'regions/{regionId}/watertemperatures?language={localeCode}',
      waterTemperaturesMaxMin: 'watertemperatures/maxmin?language={localeCode}',
      waterTemperaturesReiseradioen: 'watertemperatures/reiseradioen?language={localeCode}'
    },
    retries: {
      server: 0,
      browser: 2
    },
    staleIfError: 180,
    timeout: isBrowserTest ? 500 : 5000
  },
  internal: {
    printPathTemplate: {
      en: 'print/forecast/{locationId}/{urlPath}',
      nb: 'utskrift/værvarsel/{locationId}/{urlPath}',
      sme: 'čálihit/dálkedieđáhus/{locationId}/{urlPath}',
      nn: 'utskrift/vêrvarsel/{locationId}/{urlPath}'
    },
    svgPathTemplate: {
      en: 'content/{locationId}/meteogram.svg',
      nb: 'innhold/{locationId}/meteogram.svg',
      sme: 'sisdoallu/{locationId}/meteogram.svg',
      nn: 'innhald/{locationId}/meteogram.svg'
    }
  },
  external: {
    airQuality: {
      nb: 'https://luftkvalitet.miljodirektoratet.no/',
      sme: 'https://luftkvalitet.miljodirektoratet.no/',
      nn: 'https://luftkvalitet.miljodirektoratet.no/',
      en: 'https://luftkvalitet.miljodirektoratet.no/?sprok=en'
    },
    desktop: 'https://retro.yr.no',
    development: {
      sme: 'https://hjelp.yr.no/hc/no/articles/360020303414-Ofte-stilte-sp%C3%B8rsm%C3%A5l-FAQ-Nye-og-gamle-nettsider',
      nb: 'https://hjelp.yr.no/hc/no/articles/360020303414-Ofte-stilte-sp%C3%B8rsm%C3%A5l-FAQ-Nye-og-gamle-nettsider',
      nn: 'https://hjelp.yr.no/hc/no/articles/360020303414-Ofte-stilte-sp%C3%B8rsm%C3%A5l-FAQ-Nye-og-gamle-nettsider',
      en: 'https://hjelp.yr.no/hc/en-us/articles/360020303414-FAQ-Transition-to-new-website'
    },
    map: {
      raster: 'https://tiles.yr.no/styles/default/{z}/{x}/{y}@3x.png',
      windTiles: 'https://tiles.yr.no/api/wind/available.json',
      radarForecastTiles: 'https://tiles.yr.no/api/precipitation-nowcast/available.json ',
      radarObservationTiles: 'https://tiles.yr.no/api/precipitation-observations/available.json',
      temperatureTiles: 'https://tiles.yr.no/api/air-temperature/',
      temperatureMapBeta: {
        sme: 'https://hjelp.yr.no/hc/no/articles/14537591613468-Nytt-temperaturkart-p%C3%A5-Yr',
        nb: 'https://hjelp.yr.no/hc/no/articles/14537591613468-Nytt-temperaturkart-p%C3%A5-Yr',
        nn: 'https://hjelp.yr.no/hc/no/articles/14537591613468-Nytt-temperaturkart-p%C3%A5-Yr',
        en: 'https://hjelp.yr.no/hc/en-us/articles/14537591613468-New-temperature-map-at-Yr'
      },
      wmsRadarPrecipitationIntensityNordic: 'https://public-wms.met.no/verportal/verportal.map',
      wmsRadarNowcast: 'https://public-wms.met.no/verportal/radar_nowcast.map'
    },
    moon: {
      eclipse: {
        url: 'https://snl.no/m%C3%A5neform%C3%B8rkelse',
        displayUrl: 'snl.no/måneformørkelse'
      },
      phase: {
        url: 'https://snl.no/m%C3%A5nefaser',
        displayUrl: 'snl.no/månefaser'
      }
    },
    met: 'http://www.met.no',
    nrk: 'http://www.nrk.no',
    severity: {
      sme: 'https://hjelp.yr.no/hc/no/articles/360008876673',
      nb: 'https://hjelp.yr.no/hc/no/articles/360008876673',
      nn: 'https://hjelp.yr.no/hc/no/articles/360008876673',
      en: 'https://hjelp.yr.no/hc/en-us/articles/360008876673-Colour-coded-weather-warnings'
    },
    statisticsNormals: 'https://www.met.no/vaer-og-klima/ny-normal-i-klimaforskningen',
    sun: {
      solstice: {
        url: 'https://snl.no/solverv',
        displayUrl: 'snl.no/solverv'
      },
      twilight: {
        url: 'https://snl.no/tussm%C3%B8rke',
        displayUrl: 'snl.no/tussmørke'
      }
    },
    tide: {
      nb: {
        url: 'https://kartverket.no/til-sjos/se-havniva',
        displayUrl: 'kartverket.no/til-sjos/se-havniva'
      },
      nn: {
        url: 'https://kartverket.no/til-sjos/se-havniva',
        displayUrl: 'kartverket.no/til-sjos/se-havniva'
      },
      sme: {
        url: 'https://kartverket.no/til-sjos/se-havniva',
        displayUrl: 'kartverket.no/til-sjos/se-havniva'
      },
      en: {
        url: 'https://kartverket.no/en/at-sea/se-havniva',
        displayUrl: 'kartverket.no/en/at-sea/se-havniva'
      }
    },
    aurora: {
      url: 'https://www.swpc.noaa.gov/',
      displayUrl: 'swpc.noaa.gov'
    },
    twitter: { url: 'https://twitter.com/Meteorologene', displayUrl: 'twitter.com/meteorologene' },
    uv: {
      nb: {
        url: 'https://dsa.no/sol-og-solarium/slik-beskytter-du-deg-i-sola',
        displayUrl: 'dsa.no'
      },
      nn: {
        url: 'https://dsa.no/sol-og-solarium/slik-beskytter-du-deg-i-sola',
        displayUrl: 'dsa.no'
      },
      sme: {
        url: 'https://dsa.no/sol-og-solarium/slik-beskytter-du-deg-i-sola',
        displayUrl: 'dsa.no'
      },
      en: {
        url: 'https://dsa.no/en/sun-and-sunbeds/sun-protection-advices',
        displayUrl: 'dsa.no'
      }
    },
    waterTemperatures: 'https://badetemperaturer.yr.no/'
  },
  extreme: {
    precipitation: 20,
    wind: 17.2 // "Gale" in the Beaufort scale
  },
  featurePromo: {
    /**
     * To add a new snack or modal you can add an entry to "snack" or "modal"
     * with the following structure:
     * snack/modal: {
     *   featurePromoId: {
     *     maxDisplayCount: 1, // Number of times the snack or modal should be displayed
     *     endOfLife: '2021-04-14' // Optional end of life date
     *   }
     * }
     */
    snack: {},
    modal: {}
  },
  helmet: {
    hidePoweredBy: true,
    crossOriginResourcePolicy: { policy: 'same-site' as 'same-origin' | 'same-site' | 'cross-origin' | undefined },
    contentSecurityPolicy: {
      reportOnly: false,
      directives: {
        connectSrc: [
          "'self'",
          'https://*.yr.no',
          'wss://*.yr.no',
          'https://*.met.no',
          'http://*.nrk.no',
          'https://*.nrk.no',
          'https://*.k5a.io',
          'https://log.medietall.no'
        ],
        defaultSrc: ["'self'", 'https://*.yr.no'],
        frameSrc: ["'self'"],
        frameAncestors: ["'self'"],
        imgSrc: [
          "'self'",
          'https://*.yr.no',
          'https://*.nrk.no',
          'https://*.met.no',
          'https://*.tns-cs.net',
          'https://*.k5a.io',
          'https://log.medietall.no',
          'https://*.vegvesen.no',
          'data:'
        ],
        mediaSrc: ['https://*.yr.no', 'https://*.nrk.no', 'blob:', 'data:'],
        scriptSrc: [
          "'self'",
          'https://*.yr.no',
          'https://*.nrk.no',
          'https://ssl-nrkstream.tns-cs.net',
          'https://*.k5a.io',
          'https://log.medietall.no',
          'blob:',
          "'unsafe-inline'",
          "'unsafe-eval'"
        ],
        fontSrc: ["'self'", 'https://*.nrk.no'],
        styleSrc: ["'self'", "https: 'unsafe-inline'"]
      }
    }
  },
  indexPath: '/assets/index.js',
  locale: {
    defaultCode: 'nb',
    serialisable: {
      footer: false
    },
    supportedLanguages: {
      nb: { lang: 'nb-no', title: 'Bokmål' },
      nn: { lang: 'nn-no', title: 'Nynorsk' },
      sme: { lang: 'se-no', title: 'Davvisámegiella' },
      en: { lang: 'en-gb', title: 'English' }
    },
    validCodes: ['nb', 'nn', 'sme', 'en']
  },
  location: {
    prepopulated: [
      '1-72837', // Oslo
      '1-211102', // Trondheim
      '1-305409', // Tromsø
      '2-2950159', // Berlin
      '2-1850147' // Tokyo
    ],
    maxVisited: 5,
    validate: '[0-9]{1,2}-[0-9A-ZÆØÅ]{1,52}'
  },
  map: {
    bounds: {
      search: { north: 72.2, west: -21.2, south: 26.8, east: 42 },
      weather: { north: 72.2, west: -10, south: 34, east: 35.5 },
      wind: { north: 72.2, west: -10, south: 34, east: 35.5 },
      lightning: { north: 53, west: 0, south: 75, east: 35.5 },
      radar: { north: 53, west: 0, south: 72.7, east: 35.5 },
      warnings: { north: 71, west: -3, south: 57, east: 40 },
      temperature: { north: 72.2, west: -10, south: 34, east: 35.5 }
    },
    sources: {
      outline: {
        tileSize: 512,
        min: 0,
        max: 11
      },
      base: {
        tileSize: 512,
        min: 0,
        max: 11
      }
    },
    zoomLevels: {
      warnings: {
        initial: 2,
        min: 0,
        max: 10
      },
      radar: {
        initial: 5,
        min: 3,
        max: 12
      },
      weather: {
        initial: 5,
        min: 2,
        max: 12
      },
      wind: {
        initial: 5,
        min: 2,
        max: 12
      },
      lightning: {
        initial: 5,
        min: 2,
        max: 12
      },
      temperature: {
        initial: 5,
        min: 2,
        max: 12
      }
    }
  },
  'standalone-map': {
    zoomLevels: {
      radar: {
        initial: 3.5,
        min: 3,
        max: 12
      },
      weather: {
        initial: 2.5,
        min: 2,
        max: 12
      },
      wind: {
        initial: 2.5,
        min: 2,
        max: 12
      },
      lightning: {
        initial: 2.5,
        min: 2,
        max: 12
      },
      temperature: {
        initial: 2.5,
        min: 2,
        max: 12
      }
    }
  },
  page: {
    defaultLocationPage: 'forecast/daily-table',
    // Duration synced with app/vars.css
    transitionDuration: 200
  },
  port: process.env.PRIVATE_PORT || process.env.PORT || (isDev ? 3001 : 3000),
  search: {
    maxSuggestions: 5
  },
  survey: {
    enabled: false,
    url: ['https://uxmetrics.com/collect_cs/lyRNBE'], // We will evenly distribute the shown survey between the urls in this array.
    // If you add `showSurvey = {}` to local storage the defined survey will allways be shown (regardless of sampleRate), as long as `enabled` is set to true.
    sampleRate: 0.1, // Sample rate of users that will see the survey. Number between 0 and 1.
    version: 7 // This must be increased by one per new survey or no old users will see the new survey
  },
  time: {
    dayStartsAt: 0,
    nightStartsAt: 18
  },
  units: {
    precipitation: 'millimeter',
    pressure: 'hectopascal',
    snowDepth: 'centimeter',
    temperature: 'celsius',
    wind: 'metersPerSecond',
    waveHeight: 'meter'
  },
  version: process.env.APP_VERSION ?? 'none',
  caching: {
    assets: {
      immutable: {
        maxAge: '1y',
        // Unique filename
        regexp: /\.[a-z0-9]{20}(\.legacy)?(?:\.css|\.js)$/
      }
    },
    api: {
      maxAge: '1hr'
    },
    page: {
      maxAge: '5m'
    }
  },
  cookies: {
    locale: 'l',
    totalFavourited: 'tf',
    totalVisited: 'tv'
  },
  features: {
    // Enable features in development here
    // example: true
  },
  path: {
    hash: {
      favourite: 'fav',
      visit: 'visit'
    },
    query: {
      index: 'i',
      language: 'language',
      lat: 'lat',
      lon: 'lon',
      place: 'q'
    }
  },
  site: {
    baseUrl,
    contentBaseUrl,
    deployEnvironment: process.env.DEPLOYMENT_ENVIRONMENT || 'dev',
    isBeta,
    isBrowserTest,
    isDev,
    isFeature,
    isProd,
    isRelease,
    sentryApiKey: process.env.SENTRY_API_KEY,
    sentryProjectId: process.env.SENTRY_PROJECT_ID,
    spCollectorId: process.env.SNOWPLOW_COLLECTOR_ID
  },
  tracking: {
    ga: {
      id: process.env.GA_ID
    },
    tns: {
      sectionId: 'nrk/yr'
    },
    snowplow: {
      spCollectorId: process.env.SNOWPLOW_COLLECTOR_ID
    }
  }
};

export type TApiPath = keyof typeof settings.api.paths;

export default settings;
